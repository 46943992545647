import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer-about";

const TermsOfUse = () => {
    return (
        <>
            <section className="pagetitle pagetitleCONTACT">
                <div className="container">
                    <div className="pagetitle-text">
                        <h1>Terms and Conditions</h1>
                    </div>
                    <div className="pg-icon-1 d-b-mob"><img src="img/pg-icon-1.png" alt="bubble" /></div>
                    <div className="pg-icon-2"><img src="img/pg-icon-2.png" alt="bubble" /></div>
                    <div className="pg-icon-3"><img src="img/pg-icon-3.png" alt="bubble" /></div>
                </div>
            </section>
            {/* <!-- ==============================PAGETITLE SECTION--> */}
            <section className="ab-sec1 order">
                <div className="container">
                    <div className="">
                        <div className="">
                            <h2>Terms and Conditions</h2>


                        
                        <div className="para">




                            <p>The content on this website may contain information that includes or is based upon forward-looking statements within the meaning of the Private Securities Litigation Reform Act of 1995. Forward-looking statements give our expectations or forecasts of future events. You can identify these statements by the fact that they do not relate strictly to historical or current facts. They may use words such as &ldquo;anticipate&rdquo;, &ldquo;estimate&rdquo;, &ldquo;expect&rdquo;, &ldquo;project&rdquo;, &ldquo;intend&rdquo;, &ldquo;plan&rdquo;, &ldquo;believe&rdquo;, or other words and terms of similar meaning in connection with a discussion of future operating or financial performance. In particular, these include statements relating to future actions, prospective products and formulations, research and development activities related to formulations, future performance, results of current and anticipated commercialization efforts, expenses, the outcome of contingencies such as legal proceedings, and financial results. Any or all of the forward-looking statements we make could turn out to be wrong. They can be affected by inaccurate assumptions or by known or unknown risks and uncertainties. Many such factors will be important in determining Harrow&rsquo;s actual future results. Consequently, no forward-looking statement can be guaranteed. Our actual results may vary materially.</p>
                            <p>&nbsp;</p>
                            <p>We undertake no obligation to correct or update any forward-looking statements, whether as a result of new information, future events or otherwise. You are advised to consult any disclosures we make on related subjects in our recent reports filed with the Securities and Exchange Commission. In particular, you should read disclosures in those documents identified under the heading &ldquo;Risk Factors.&rdquo;</p>
                            <p>&nbsp;</p>
                            <p>1. Acceptance of terms of use and amendments<br /> Each time you use or cause access to this web site, you agree to be bound by these Terms of use, as amended from time to time with or without notice to you. In addition, if you are using a particular service on this web site or accessed via this web site, you will be subject to any rules or guidelines applicable to those services, and they will be incorporated by reference within these Terms of use. Please read the site&rsquo;s Privacy policy, which is incorporated within these Terms of use by reference.</p>
                            <p>&nbsp;</p>
                            <p>2. The site editor&rsquo;s service<br /> This web site and the services provided to you on and via this web site are provided on an &ldquo;AS IS&rdquo; basis by Harrow, Inc., (&ldquo;Harrow&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo; or &ldquo;our&rdquo;) and the administrator of this website (such administrator and Harrow are collectively referred to as the &ldquo;site editor&rdquo;). You agree that the site editor reserves the right to modify or discontinue provision of this web site and its services, and to remove the data you provide, either temporarily or permanently, at any time, without notice and without any liability towards you. The site editor will not be held responsible or liable for timeliness, removal of information, failure to store information, inaccuracy of information, or improper delivery of information.</p>
                            <p>&nbsp;</p>
                            <p>3. Your responsibilities and registration obligations<br /> In order to use this web site or certain parts of it, you may be required to register for a user account on this web site; in this case, you agree to provide truthful information when requested, and &mdash; if a minimum age is required for eligibility for a user account &mdash; you undertake that you are at least the required age. By registering for a user account, you explicitly agree to this site&rsquo;s Terms of use, including any amendments made by the site editor that are published herein.</p>
                            <p>&nbsp;</p>
                            <p>4. Privacy policy<br /> Registration data and other personally identifiable information that the site may collect is subject to the terms of the site editor&rsquo;s Privacy policy.</p>
                            <p>&nbsp;</p>
                            <p>5. Registration and password<br /> You are responsible for maintaining the confidentiality of your password, and you will be responsible for all usage of your user account and/or user name, whether authorized or not authorized by you. You agree to immediately notify the site editor of any unauthorized use of your user account, user name or password.</p>
                            <p>&nbsp;</p>
                            <p>6. Your conduct<br /> You agree that all information or data of any kind, whether text, software, code, music or sound, photographs or graphics, video or other materials (&ldquo;content&rdquo;), made available publicly or privately, will be under the sole responsibility of the person providing the said content, or of the person whose user account is used. You agree that this web site may expose you to content that may be objectionable or offensive. The site editor will not be responsible to you in any way for content displayed on this web site, nor for any error or omission.</p>
                            <p>&nbsp;</p>
                            <p>By using this web site or any service provided, you explicitly agree that:</p>
                            <p>&nbsp;</p>
                            <p>(a) you will not provide any content or conduct yourself in any way that may be construed as: unlawful; illegal; threatening; harmful; abusive; harassing; stalking; tortious; defamatory; libelous; vulgar; obscene; offensive; objectionable; pornographic; designed to interfere with or disrupt the operation of this web site or any service provided; infected with a virus or other destructive or deleterious programming routine; giving rise to civil or criminal liability; or in violation of an applicable local, national or international law;</p>
                            <p>&nbsp;</p>
                            <p>(b) you will not impersonate or misrepresent your association with any person or entity; you will not forge or otherwise seek to conceal or misrepresent the origin of any content provided by you;</p>
                            <p>&nbsp;</p>
                            <p>(c) you will not collect or harvest any information about other users;</p>
                            <p>&nbsp;</p>
                            <p>(d) you will not provide, and you will not use this web site to provide, any content or service in any commercial manner, or in any manner that would involve junk mail, spam, chain letters, pyramid schemes, or any other form of unauthorized advertising or commerce; you will not use this web site to promote or operate any service or content without the site editor&rsquo;s prior written consent;</p>
                            <p>&nbsp;</p>
                            <p>e) you will not provide any content that may give rise to the site editor being held civilly or criminally liable, or that may be considered a violation of any local, national or international law, including &mdash; but not limited to &mdash; laws relating to copyrights, trademarks, patents, or trade secrets.</p>
                            <p>&nbsp;</p>
                            <p>7. Submission of content on this web site<br /> By providing any content to this web site:</p>
                            <p>&nbsp;</p>
                            <p>(a) you agree to grant the site editor a worldwide, royalty-free, perpetual, non-exclusive right and license (including any moral rights or other necessary rights.) to use, display, reproduce, modify, adapt, publish, distribute, perform, promote, archive, translate, and to create derivative works and compilations, in whole or in part. Such license will apply with respect to any form, media, technology already known at the time of provision or developed subsequently;</p>
                            <p>&nbsp;</p>
                            <p>(b) you warrant and represent that you have all legal, moral, and other rights that may be necessary to grant the site editor the license specified in this section 7;</p>
                            <p>&nbsp;</p>
                            <p>(c) you acknowledge and agree that the site editor will have the right (but not obligation), at the site editor&rsquo;s entire discretion, to refuse to publish, or to remove, or to block access to any content you provide, at any time and for any reason, with or without notice.</p>
                            <p>&nbsp;</p>
                            <p>8. Third-party content and services<br /> Goods and services of third parties may be advertised and/or may be made available on or through this web site. Representations made regarding products and services provided by third parties will be governed by the policies and representations made by these third parties. The site editor will not in any manner be liable for or responsible for any of your dealings or interaction with third parties.</p>
                            <p>&nbsp;</p>
                            <p>In addition, portions of the content on this web site may have been contributed by third parties. &nbsp;The inclusion of such information does not indicate any approval or endorsement by Harrow of such content, and Harrow expressly disclaims any liability with respect to the foregoing.</p>
                            <p>&nbsp;</p>
                            <p>This web site may contain links to other Internet web sites, including affiliated web sites, which may or may not be owned or operated by us. &nbsp;We have not reviewed the web sites that are linked to this web site, and we have no control over such web sites. &nbsp;Unless otherwise expressly stated, we are not responsible for the availability or content of such web sites, any updates or changes to such web sites, or the privacy policies or other practices of such web sites, and the fact that we offer links to such web sites does not indicate any approval or endorsement of any material contained on any such web site. &nbsp;We are providing these links to other web sites to you only as a convenience. &nbsp;Accordingly, we strongly encourage you to become familiar with the terms of use and privacy and other practices of any such web site. &nbsp;We make no representations or warranties as to the security of any information that you may provide or be requested to provide to any third party, whether through such a third party web site or otherwise. &nbsp;It is up to you to take precautions to ensure that whatever links you select (whether from this web site or other web sites) is free of such items as viruses, worms, trojan horses, defects, date bombs, time bombs and other items of a destructive nature. &nbsp;YOU AGREE THAT YOUR USE OF THIRD-PARTY WEB SITES AND RESOURCES, INCLUDING WITHOUT LIMITATION YOUR USE OF ANY CONTENT, INFORMATION, DATA, ADVERTISING, PRODUCTS, OR OTHER MATERIALS ON OR AVAILABLE THROUGH SUCH WEB SITES AND RESOURCES, IS AT YOUR OWN RISK AND IS SUBJECT TO THE TERMS AND CONDITIONS OF USE APPLICABLE TO SUCH WEB SITES AND RESOURCES.</p>
                            <p>&nbsp;</p>
                            <p>Additionally, other web sites may provide links to this web site with or without our authorization. &nbsp;You acknowledge and agree that Harrow and its affiliates, customers, suppliers and agents do not endorse such web sites, and are not and shall not be responsible or liable for any links from those web sites to this web site, any content, advertising, products or other materials available on or through such other web sites, or any loss or damages incurred in connection therewith. &nbsp;We shall have the right, at any time and in our sole discretion, to block links to this web site through technological or other means without prior notice.</p>
                            <p>&nbsp;</p>
                            <p>9. Content Provided by Harrow<br /> The content provided by Harrow on this web site and all site-related services is intended to educate and inform you about Harrow, our product candidates and our technology. &nbsp;Although we strive to provide content that is both useful and accurate, laws, regulations, data and other information change frequently. &nbsp;In addition, the facts and circumstances of every situation differ. &nbsp;Accordingly, although Harrow endeavors to use reasonable care in assembling the content that we provide on this web site, some of it may not be accurate or complete. &nbsp;In addition, although we try to keep the web site up-to-date, we assume no duty to update any of the content on the web site, all of which speaks only as of the date of its posting. &nbsp;Please contact Harrow to determine whether you have the most recent version of the content that we generally make available on this web site, although even the most recent version available from Harrow may not be up-to-date, accurate or complete.</p>
                            <p>&nbsp;</p>
                            <p>We hope that the content that we have provided on this web site will be helpful as background reference, but it should in no way be considered to be legal, accounting or other professional advice on any subject matter. Harrow is not engaged in rendering legal, accounting or other professional services, and the &nbsp;availability or use of this web site is not intended to create, and does not create, any attorney-client, accounting-client or other professional services relationship. &nbsp;Use of this web site is not an adequate substitute for obtaining legal, accounting or other professional advice. &nbsp;You should not take action based on any of the content on the web site without first seeking the services of a competent professional, and any such action that you elect to take is at your own risk.</p>
                            <p>&nbsp;</p>
                            <p>10. Investment Information<br /> The information on this web site may not be current and should not be used or relied on for any decision to invest in, purchase, retain, sell or otherwise transfer the stock of Harrow or for any related purpose. &nbsp;The information provided on this web site does not constitute an offer to purchase shares of Harrow and is not aimed at investors or potential investors in Harrow stock. &nbsp;Any decision to invest in Harrow should be based on separate material approved by Harrow. &nbsp;Furthermore, Harrow reserves the right to change or supplement the information provided on this web site.</p>
                            <p>&nbsp;</p>
                            <p>11. Stock Prices<br /> All stock price information provided on this web site is for informational purposes only, and is not intended, and should not be relied on, for investment purposes. &nbsp;Historical and current stock price information is not indicative of future performance. &nbsp;All stock price information has been provided by a third-party source and not by Harrow, and Harrow makes no representations or warranties as to the sequence, accuracy, or completeness of any stock price information or other related data displayed on this web site. &nbsp;Harrow shall not be responsible or liable in any way to a web site visitor for any delays, inaccuracies, errors in, or omission of any such stock price information or related data or the transmission thereof, or for any actions taken in reliance thereon or for any damages arising therefrom or occasioned thereby or by reason of nonperformance or interruption, or termination, of the stock price information for any cause whatsoever.</p>
                            <p>&nbsp;</p>
                            <p>12. Indemnification<br /> You agree to indemnify and hold harmless the site editor and the site editor&rsquo;s representatives, subsidiaries, affiliates, related parties, officers, directors, employees, agents, independent contractors, advertisers, partners, and co-branders, from any loss, damage, expense, claim or demand, including reasonable legal fees, that may be filed by any third party, arising out of your conduct or connection with this web site or service, your provision of content, your violation of these Terms of use, or any other violation by you of the rights of another person or party.</p>
                            <p>&nbsp;</p>
                            <p>13. DISCLAIMER OF WARRANTIES<br /> YOU UNDERSTAND AND AGREE THAT YOUR USE OF THIS WEB SITE AND OF ANY SERVICES OR CONTENT PROVIDED (COLLECTIVELY, THE &ldquo;SERVICE&rdquo;) IS AT YOUR OWN RISK. SERVICES AND CONTENT ARE PROVIDED TO YOU &ldquo;AS IS&rdquo;, AND THE SITE EDITOR EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, EITHER IMPLIED OR EXPRESS, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. THE SITE EDITOR MAKES NO WARRANTY, EITHER IMPLIED OR EXPRESS, THAT ANY PART OF THE SERVICE WILL BE UNINTERRUPTED, ERROR-FREE, VIRUS-FREE, TIMELY, SECURE, ACCURATE, RELIABLE, OR OF ANY QUALITY, NOR IS IT WARRANTED EITHER IMPLICITLY OR EXPRESSLY THAT ANY CONTENT IS SAFE IN ANY MANNER FOR DOWNLOAD. YOU UNDERSTAND AND AGREE THAT NEITHER THE SITE EDITOR NOR ANY PARTICIPANT IN THE SERVICE PROVIDES PROFESSIONAL ADVICE OF ANY KIND AND THAT ANY ADVICE OR ANY OTHER INFORMATION OBTAINED VIA THIS WEB SITE MAY BE USED SOLELY AT YOUR OWN RISK, AND THAT THE SITE EDITOR WILL NOT BE HELD LIABLE IN ANY WAY.</p>
                            <p>&nbsp;</p>
                            <p>Some jurisdictions may not allow disclaimers of implied warranties, and certain statements in the above disclaimer may not apply to you as regards implied warranties; the other terms and conditions remain enforceable notwithstanding.</p>
                            <p>&nbsp;</p>
                            <p>14. LIMITATION OF LIABILITY<br /> YOU EXPRESSLY UNDERSTAND AND AGREE THAT NEITHER THE SITE EDITOR NOR ANY OF ITS AFFILIATES, LICENSORS, SPONSORS, DIRECTORS, OFFICERS, EMPLOYEES, CONSULTANTS, AGENTS OR REPRESENTATIVES WILL BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL PUNITIVE OR EXEMPLARY DAMAGES. UNDER ANY THEORY OF LIABILITY, ARISING OUT OF OR RELATING IN ANY WAY TO THE WEBSITE, THE SERVICES OR THE CONTENT. THIS INCLUDES, BUT IS NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF THE SITE EDITOR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM (I) THE USE OF SERVICES OR THE INABILITY TO USE THE SERVICES, (II) THE COST OF OBTAINING SUBSTITUTE GOODS AND/OR SERVICES RESULTING FROM ANY TRANSACTION ENTERED INTO ON OR THROUGH THE SERVICES, (III) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR DATA TRANSMISSIONS, (IV) STATEMENTS BY ANY THIRD PARTY OR CONDUCT OF ANY THIRD PARTY USING SERVICES, OR (V) ANY OTHER MATTER RELATING TO THE SERVICES. THE SOLE AND EXCLUSIVE MAXIMUM LIABILITY TO THE SITE EDITOR FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION SHALL BE THE TOTAL AMOUNT PAID BY YOU, IF ANY, TO ACCESS THIS WEBSITE (EXCLUSIVE OF ANY INTERNET ACCESS FEES OR COSTS OF COMPUTER OR OTHER EQUIPMENT THAT YOU MAY USE IN ACCESSING THE WEBSITE).</p>
                            <p>&nbsp;</p>
                            <p>In some jurisdictions, it is not permitted to limit liability and, therefore, such limitations may not apply to you.</p>
                            <p>&nbsp;</p>
                            <p>15. Reservation of rights<br /> The site editor reserves all of the site editor&rsquo;s rights, including but not limited to any and all copyrights, trademarks, patents, trade secrets, and any other proprietary right that the site editor may have in respect of this web site, its content, and goods and services that may be provided on or through this website. The use of the site editor&rsquo;s rights and property requires the site editor&rsquo;s prior written consent. By making services available to you, the site editor is not providing you with any implied or express licenses or rights, and you will have no rights to make any commercial use of this web site the content provided here on or provided services without the site editor&rsquo;s prior written consent.</p>
                            <p>&nbsp;</p>
                            <p>16. Notification of copyright infringement<br /> If you believe that your property has been used in any way that could be considered a copyright infringement or a violation of your intellectual property rights, the site editor&rsquo;s copyright agent may be contacted via: e-mail to the site administrator</p>
                            <p>&nbsp;</p>
                            <p>17. Applicable law<br /> You agree that these Terms of use and any dispute arising out of your use of this web site or products or services provided will be governed by and construed in accordance with the laws of California notwithstanding any differences between the said applicable legislation and legislation in force at your location. By registering for a user account on this web site, or by using this web site and the services it provides, you accept that jurisdiction is granted to the courts in the state of California, and that any disputes will be heard by the said courts.</p>
                            <p>&nbsp;</p>
                            <p>18. Return Policy<br /> We regret that we cannot accept returns on any custom compounds or commercial prescription products as State and Federal regulations prohibit the return and resale of such items. All products are non-returnable and non-refundable. However, if you feel we have made an error in filling your order or your order arrives damaged, please contact Customer Support <span className="s3">at <b><u><a href="tel:8444466979">(844) 446-6979</a></u></b></span> within 24 hours of receiving your order for assistance.</p>
                            <p>&nbsp;</p>
                            <p>19. Miscellaneous information<br /> (i) In the event that any provision of these Terms of use is deemed to conflict with legislation by a court with jurisdiction over the parties, the said provision will be interpreted to reflect the original intentions of the parties in accordance with applicable law, and the remainder of these Terms of use will remain valid and applicable; (ii) The failure of either party to assert any right under these Terms of use will not be considered to be a waiver of that party&rsquo;s right, and the said right will remain in full force and effect; (iii) You agree that any claim or cause in respect of this web site or its services must be filed within one (1) year after such claim or cause arose, or the said claim or cause will be forever barred, without regard to any contrary legislation; (iv) The site editor may assign the site editor&rsquo;s rights and obligations under these Terms of use; in this event, the site editor will be relieved of any further obligation.</p>
                            <p>&nbsp;</p>
                            <p>The content on this page may contain information that includes or is based upon forward-looking statements within the meaning of the Private Securities Litigation Reform Act of 1995. &nbsp;Forward-looking statements give our expectations or forecasts of future events. &nbsp;You can identify these statements by the fact that they do not relate strictly to historical or current facts. &nbsp;They may use words such as &ldquo;anticipate&rdquo;, &ldquo;estimate&rdquo;, &ldquo;expect&rdquo;, &ldquo;project&rdquo;, &ldquo;intend&rdquo;, &ldquo;plan&rdquo;, &ldquo;believe&rdquo;, or other words and terms of similar meaning in connection with a discussion of future operating or financial performance. &nbsp;In particular, these include statements relating to future actions, prospective products, clinical trial procedures and results, future performance, results of current and anticipated products, expenses, the outcome of contingencies such as legal proceedings, and financial results.</p>
                            <p>&nbsp;</p>
                            <p>Any or all of the forward-looking statements we make could turn out to be wrong. They can be affected by inaccurate assumptions or by known or unknown risks and uncertainties. Many such factors will be important in determining our actual future results. Consequently, no forward-looking statement can be guaranteed. Our actual results may vary materially, and there are no guarantees about the performance of our stock.</p>
                            <p>&nbsp;</p>
                            <p>We undertake no obligation to correct or update any forward-looking statements, whether as a result of new information, future events or otherwise. &nbsp;You are advised to consult any disclosures we make on related subjects in our recent reports filed with the Securities and Exchange Commission. In particular, you should read disclosures in those documents identified under the heading &ldquo;Risk Factors.&rdquo;</p>
                        </div>
                    </div>

                    </div>
                </div>
            </section>
            {/* <!-- ==============================APPROVED USE SECTION--> */}
            <Footer />
        </>
    )
}  
export default TermsOfUse
