import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer-no-references";

const Reimbursement=()=>{
    return(
        <>
        <section className="pagetitle pagetitleCONTACT">
         <div className="container">
            <div className="pagetitle-text">
               <h1>No-Hassle Reimbursement Support</h1>
            </div>
            <div className="pg-icon-2"><img src="img/pg-icon-2.png" alt="bubble"/></div>
            <div className="pg-icon-3"><img src="img/pg-icon-3.png" alt="bubble"/></div>
         </div>
      </section>
       {/* <!-- ==============================PAGETITLE SECTION--> */}
      <section className="ab-sec1 Reimbursement">
         <div className="container">
            <div className="Reimbursement-row">
               <div className="Reimbursement-col-L">
                  <h2>IHEEZO is the only FDA-approved topical ocular anesthetic with a permanent J-code</h2>
                  <div className="para para-bold">
                     <p>Permanent J-code: J-2403 can help facilitate reimbursement.</p>
                  </div>
                  <div className="para">
                     <ul>
                        <li><p>IHEEZO™ is reimbursed per milligram. Therefore, providers should always use 1 mg as the billing unit and bill for 800 units when IHEEZO is utilized.</p></li>
                     </ul>
                  </div>
                  <div className="Reimbursement-box Reimbursement-box-disblock" style={{background:"#293b97",paddingBottom:"20px",paddingLeft:"20px",paddingRight:"20px"}}>
                     <div className="Reimbursement-box-a">
                        <a style={{marginTop:"15px",fontSize:"20px"}} href="#">Permanent J-code: J-2403</a>
                     </div>
                     
                     <div className="Reimbursement-box-pdf"> <a href="/img/BillingandCodingGuide.pdf" target="_blank"> <div className="pdf-icon"> <img
                     src="img/pdf-icon.png" alt="pdf icon"/> </div> <p style={
                     {color:"#293b97"}}>Download Billing & Coding Guide</p> </a> </div> </div> <div
                     className="support-team"> <h2>Your dedicated reimbursement support team</h2> <div
                     className="para"> <p>When you order IHEEZO, you will be assigned a dedicated
                     Reimbursement Business Manager. They can help support your coding, billing,
                     and claims submission questions.<span className="sup">*</span></p> </div> <div
                     className="button-div"> <a href="/contact">Contact us</a> </div> </div> </div>
                     <div className="Reimbursement-col-R"> <div className="Reimbursement-box
                     Reimbursement-box-disnone" style={
                     {background:"#293b97",paddingBottom:"20px",paddingLeft:"20px",paddingRight:"20px"}}>
                     <div className="Reimbursement-box-a"> <a style={
                     {marginTop:"15px",fontSize:"20px"}} href="#">Permanent J-code: J-2403</a>
                     </div> <div className="Reimbursement-box-pdf"> <a href="/img/BillingandCodingGuide.pdf" target="_blank"> <div className="pdf-icon">
                     <img src="img/pdf-icon.png" alt="pdf icon"/> </div> <p style={
                     {color:"#293b97"}}>Download Billing & Coding Guide</p> </a> </div> </div>
                     </div> </div> <div className="para last-para"> <p style={
                     {fontSize:"14px"}}>*Individual plan coverage, payment, policies, and
                     procedures vary and should be confirmed by the facility. Harrow does not
                     guarantee reimbursement.</p> </div> </div> </section>{/* <!--
                     ==============================APPROVED USE SECTION--> */} <Footer/> </> ) }
                     export default Reimbursement
