import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer-no-references";
import "./order.css"

const Order = () => {
   return (
      <>
         <section className="pagetitle pagetitleCONTACT">
            <div className="container">
               <div className="pagetitle-text">
                  <h1>Order IHEEZO</h1>
               </div>
               <div className="pg-icon-1 d-b-mob"><img src="img/pg-icon-1.png" alt="bubble" /></div>
               <div className="pg-icon-2"><img src="img/pg-icon-2.png" alt="bubble" /></div>
               <div className="pg-icon-3"><img src="img/pg-icon-3.png" alt="bubble" /></div>
            </div>
         </section>
         {/* <!-- ==============================PAGETITLE SECTION--> */}
         <section className="ab-sec1 order">
            <div className="container">
               <div className="order-row">
                  <div className="order-col-L">
                     <h2>Ordering Information</h2>
                     <div className="para">
                        <p>IHEEZO™ is a low-viscosity gel supplied in a sterile, single-use unit. It contains 24 mg of chloroprocaine hydrochloride per unit (800 mg). Each package ordered consists of one unit.</p>
                     </div>
                     <div className="para">
                        <p>Every step of the ordering and reimbursement process for IHEEZO is supported by a dedicated Reimbursement Business Manager, whom you will be connected with when you choose to use IHEEZO.</p>
                     </div>
                  
                  </div>
                  <div className="order-col-R">
                     <div className="order-box">
                        <p>For more information about IHEEZO, contact us at<a href="tel:844-446-6979"><b> 844-446-6979 </b></a>from 8 am to 8 pm ET or email us at <a href="mailto:info@iheezo.com"><b>info@iheezo.com.</b></a></p>
                     </div>
                  </div>
               </div>
               <div>
                        <table>
                        <tbody>
                           <tr>
                              <th>NDC #</th>
                              <th>Unit Size</th>
                              <th>Purchase Quantity</th>
                           </tr>
                           <tr>
                              <td>82667-300-01</td>
                              <td>0.3mL (800 mg)</td>
                              <td>1 unit</td>
                           </tr>
                           </tbody>
                        </table>
                       
                     </div>
                     <div style={{marginTop:"15px"}}>
                         <table>
                         <tbody>
                           <tr>
                              <th className="text-left">Wholesaler / Distributor</th>
                              <th>Item Number</th>
                              <th>Contact Phone Number</th>
                           </tr>
                           <tr>
                              <td className="text-left">AmerisourceBergen</td>
                              <td>#10278824</td>
                              <td>(800) 746-6273</td>
                           </tr>
                           <tr>
                              <td className="text-left">McKesson Medical Surgical</td>
                              <td>#1228615</td>
                              <td>(855) 571-2100</td>
                           </tr>
                           <tr>
                              <td className="text-left">McKesson Pharmaceutical</td>
                              <td>#2801066</td>
                              <td>(855) 625-7385</td>
                           </tr>
                           {/* <tr>
                              <td className="text-left">McKesson</td>
                              <td style={{color:"#ed2c4e"}}>[xxxxxxx]</td>
                              <td style={{color:"#ed2c4e"}}>[xxx-xxx-xxxx]</td>
                           </tr> */}
                           </tbody>
                        </table>
                     </div>
            </div>
         </section>
         {/* <!-- ==============================APPROVED USE SECTION--> */}
         <Footer />
      </>
   )
}
export default Order
