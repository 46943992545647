import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer-dosing";

const Anesthesia = () => {
   return (
      <>
         <section className="pagetitle pagetitleCONTACT">
            <div className="container">
               <div className="pagetitle-text">
                  <h1>IHEEZO Gives You No Uncertainty</h1>
               </div>
               <div className="pg-icon-1"><img src="img/pg-icon-1.png" alt="bubble" /></div>
               <div className="pg-icon-2"><img src="img/pg-icon-2.png" alt="bubble" /></div>
               <div className="pg-icon-3"><img src="img/pg-icon-3.png" alt="bubble" /></div>
            </div>
         </section>
         {/* <!-- ==============================PAGETITLE SECTION--> */}
         <section className="ab-sec1 anesthesia anesthesiaPage">
            <div className="container1">
               <div className="protocol5" style={{ marginTop: "30px" }}>
                  <div className="container">
                     <div className="pro-row">
                        <div className="pro-col-LM">
                           <h2>No unpredictability with sterile, single-use packaging</h2>

                           <div>
                              <p style={{ fontSize: "18px" }}>IHEEZO<span className="sup">TM</span> is delivered in a sterile, single-use ampule for added patient safety.<span className="sup">1</span> Single-use packaging decreases the risk of infection and medication errors.<span className="sup" style={{display:"inline"}}>2</span></p>
                              <p style={{ fontSize: "18px" }}>Each single-use contains 24 mg of chloroprocaine in 800 mg of gel.<span className="sup">1</span></p>
                              <p style={{ fontSize: "18px" }}>Do not touch the dropper tip to any surface as this may contaminate the gel.<span className="sup">1</span></p>
                              <p style={{ fontSize: "18px" }}>Advise patients that their eyes will be insensitive for up to 20 minutes due to the effect of the anesthetic and that care should be taken to avoid accidental injuries.<span className="sup">1</span></p>
                           </div>
                           {/* <div className="button-div">
                              <a href="/order">Order IHEEZO now</a>
                           </div> */}
                        </div>

                     </div>

                  </div>
               </div>
               
               <br/>
               <div className="protocol1">
                  <div className="container">
                     <div className="pro-row">


                        <div className="pro-col-L">
                           <div className="pro1-header">
                              <h2 id="anesthesiaH2">Rapid onset of sufficient anesthesia acheived in less than 90 seconds</h2>
                              <div id="mobile-view-anesthesia-left">
                              <div className="anesthesiaSec2">
                              <div className="anesthesiaCols anesthesiaCols-1">
                              </div>
                           </div>
                           <div className="anesthesiaSec" style={{display:"inline-block"}}>
                              <div className="anesthesiaCol anesthesiaCol-1">
                                 <div className="dropIconbox">
                                    <img src="img/dropimg.png" alt="procedure icon" />
                                    <span>IHEEZO</span>
                                 </div>
                              </div>

                              <div className="anesthesiaCol anesthesiaCol-2">
                                 <div className="dropIconbox">
                                    <img src="img/dropimg.png" alt="procedure icon" />
                                    <span>IHEEZO</span>
                                 </div>
                              </div>
                              <div className="anesthesiaCol anesthesiaCol-3">
                                 <div className="dropIconbox">
                                    <img src="img/dropimg.png" alt="procedure icon" />
                                    <span>IHEEZO</span>
                                 </div>
                              </div>
                              <div className="anesthesiaCol anesthesiaCol-4">
                                 <div className="dropIconbox">
                                    <img src="img/procedureIcon.png" alt="procedure icon" />
                                    <span>Procedure</span>
                                 </div>
                              </div>
                              
                           </div>
                           </div>
                           </div>
                           
                        </div>
                        <div className="pro-col-R">
                              <ul className="text-left">
                                 <li>
                                    <p><b>In studies 1 and 2 in healthy volunteers, the median time for achieving anesthesia was 40 seconds.<span className="sup">1</span></b></p>
                                 </li>
                                 <li>
                                    <p><b>In study 3, the average time for achieving anesthesia was less than 90 seconds.<span className="sup">1</span></b></p>
                                 </li>
                                 <li>
                                    <p>A single dose of IHEEZO&#8212;just 3 drops&#8212;is enough to ensure sufficient anesthesia for routine ophthalmic procedures.<span className="sup">1</span></p>
                                 </li>
                              </ul>
                           </div>
                           <div className="pro-col-L" id="mobile-view-anesthesia">
                           <div className="anesthesiaSec2">
                              <div className="anesthesiaCols anesthesiaCols-1">
                              </div>
                           </div>
                           <div className="anesthesiaSec" style={{display:"inline-block"}}>
                              <div className="anesthesiaCol anesthesiaCol-1">
                                 <div className="dropIconbox">
                                    <img src="img/dropimg.png" alt="drop icon" />
                                    <span>IHEEZO</span>
                                 </div>
                              </div>

                              <div className="anesthesiaCol anesthesiaCol-2">
                                 <div className="dropIconbox">
                                    <img src="img/dropimg.png" alt="drop icon" />
                                    <span>IHEEZO</span>
                                 </div>
                              </div>
                              <div className="anesthesiaCol anesthesiaCol-3">
                                 <div className="dropIconbox">
                                    <img src="img/dropimg.png" alt="drop icon" />
                                    <span>IHEEZO</span>
                                 </div>
                              </div>
                              <div className="anesthesiaCol anesthesiaCol-4">
                                 <div className="dropIconbox">
                                    <img src="img/procedureIcon.png" alt="drop icon" />
                                    <span>Procedure</span>
                                 </div>
                              </div>
                              
                           </div>
                           
                        </div>
                     </div>
                     
                  </div>
               </div>

               <div className="protocol2">
                  <div className="container">
                     <div className="pro2box">
                        <p  style={{maxWidth:"900px", margin:"auto"}}>IHEEZO has a novel low-viscosity gel formulation that is<span style={{ color: "#e9be65" }}> 75% less viscous</span> than other FDA-approved anesthetic gels, providing ocular surface coverage right where it's needed.<span className="sup">3,4</span></p>
                     </div>
                  </div>
               </div>

               <div>
               <div className="protocol5">
               <div className="container">
                     <div className="pro-row">
                     <div className="pro-col-LM">
                  <h4 style={{ marginTop: "30px", color: "#293b97", textAlign: "center", fontWeight: "700" }}>Viscosity comparisons<span className="sup">3-5</span></h4>
                  <iframe className="iframe1" src="https://iheezo.com/viscocity-chart-horizontal.html" style={{ width: "100%", height: "310px", border: "none", margin: "auto" }}>
                  </iframe>
                  <iframe className="iframe2" src="https://iheezo.com/viscocity-chart.html" style={{ width: "100%", height: "300px", border: "none", margin: "auto" }}></iframe>
                     <div className="para last-para graphLastPara">
                        <p style={{ fontSize: "14px" }}>The comparison chart is only comparing the viscosity of preparations. This chart is not intended to compare the safety or efficacy of these products.</p>
                     </div>
                     <div className="button-div" style={{ marginTop: "30px" }}>
                        <a href="/order">Learn how to order IHEEZO</a>
                     </div>

                  </div>
                  </div>         
                  </div>   
                  </div>   
               </div>


               
            </div>
         </section>

         <Footer />

      </>
   )
}
export default Anesthesia
