import * as Yup from "yup";

export const SignupSchema= Yup.object().shape({
    first_name:Yup.string().min(3).required("Required"),
    last_name:Yup.string().min(3).required("Required"),
    npi_number:Yup.string().min(3).required("Required"),
    state_licence_no:Yup.string().min(3).required("Required"),
    street:Yup.string().min(3).required("Required"),
    city:Yup.string().min(3).required("Required"),
    zipcode:Yup.string().min(3).required("Required"),
    facility_name:Yup.string().min(3).required("Required"),
    // suite:Yup.string().min(3).required("Required"),
    state_license:Yup.string().oneOf(["Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
"Maryland",
"Massachusetts",
"Michigan",
"Minnesota",
"Mississippi",
"Missouri",
"Montana",
"Nebraska",
"Nevada",
"New Hampshire",
"New Jersey",
"New Mexico",
"New York",
"North Carolina",
"North Dakota",
"Ohio",
"Oklahoma",
"Oregon",
"Pennsylvania",
"Rhode Island",
"South Carolina",
"South Dakota",
"Tennessee",
"Texas",
"Utah",
"Vermont",
"Virginia",
"Washington",
"West Virginia",
"Wisconsin",
"Wyoming"],"Required").required("Required"),
    sub_speciality:Yup.string().oneOf(["Cataract","Retina","Other"],"Required").required("Required"),
    state:Yup.string().oneOf(["Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
"Maryland",
"Massachusetts",
"Michigan",
"Minnesota",
"Mississippi",
"Missouri",
"Montana",
"Nebraska",
"Nevada",
"New Hampshire",
"New Jersey",
"New Mexico",
"New York",
"North Carolina",
"North Dakota",
"Ohio",
"Oklahoma",
"Oregon",
"Pennsylvania",
"Rhode Island",
"South Carolina",
"South Dakota",
"Tennessee",
"Texas",
"Utah",
"Vermont",
"Virginia",
"Washington",
"West Virginia",
"Wisconsin",
"Wyoming"],"Required").required("Required"),

   
})

export const ContactSchema=Yup.object().shape({
    states:Yup.string().oneOf(["Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
"Maryland",
"Massachusetts",
"Michigan",
"Minnesota",
"Mississippi",
"Missouri",
"Montana",
"Nebraska",
"Nevada",
"New Hampshire",
"New Jersey",
"New Mexico",
"New York",
"North Carolina",
"North Dakota",
"Ohio",
"Oklahoma",
"Oregon",
"Pennsylvania",
"Rhode Island",
"South Carolina",
"South Dakota",
"Tennessee",
"Texas",
"Utah",
"Vermont",
"Virginia",
"Washington",
"West Virginia",
"Wisconsin",
"Wyoming"],"Required").required("Required"),

first_name:Yup.string().min(3).required("Required"),
last_name:Yup.string().min(3).required("Required"),
email:Yup.string().email("Please enter a valid email").required("Required"),

phone_no:Yup.number().typeError("Please enter valid number").min(10000000, "Please enter valid number").max(9999999999, "Please enter valid number"),

roll_no:Yup.string().oneOf(["Healthcare Professional","Medical Staff","Other"],"Required").required("Required"),
sub_specialty:Yup.string().oneOf(["Cataract & Refractive","Retina","Anesthesia","Other"],"Required").required("Required")
})