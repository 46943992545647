import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer-about";

const About=()=>{
    return(
        <>
      {/* <!-- ==============================PAGETITLE SECTION--> */}
      <section className="pagetitle pagetitleCONTACT">
         <div className="container">
            <div className="pagetitle-text">
               <h1>IHEEZO Gives You No Surprises</h1>
            </div>
            <div className="pg-icon-1"><img src="img/pg-icon-1.png" alt="bubble"/></div>
            <div className="pg-icon-2"><img src="img/pg-icon-2.png" alt="bubble"/></div>
            <div className="pg-icon-3"><img src="img/pg-icon-3.png" alt="bubble"/></div>
         </div>
      </section>
       {/* <!-- ==============================PAGETITLE SECTION--> */}
      <section className="ab-sec1">
         <div className="container1">
            <div className="protocol1">
               <div className="container">
               <div className="pro-row">
                  <div className="pro1-header" style={{marginBottom:"40px"}}>
                     <h2>No delays to your existing surgical protocols</h2>
                     <p>A single dose of low-viscosity IHEEZO™ was shown to sustain sufficient ocular surface anesthesia for the duration of routine cataract surgery, lasting an average of 21.5 minutes.<span className="sup">1</span></p>
                  </div>
                  <div className="pro-col-L">
                     <div className="img3box-row">
                        <div className="img3box-col">
                           <div className="img3box-title">
                              <h4>Average Onset</h4>
                              <p>Rapid onset of less than 90 seconds to achieve sufficient anesthesia</p>
                           </div>
                           <div className="img3box-orig">
                              <img src="img/onset.png" alt="90 sec"/>
                              <span>&lt;90<br/>sec.</span>
                           </div>
                           <div className="img3box-shadow">
                              <img src="img/shadow-image.png" alt="90 sec shadow"/>
                           </div>
                        </div>
                        <div className="img3box-col">
                           <div className="img3box-title">
                              <h4>Average Procedure</h4>
                              <p>Many typical ophthalmic procedures last 10 to 15 minutes</p>
                           </div>
                           <div className="img3box-orig">
                              <img src="img/procedure.png" alt="10-15 min"/>
                              <span>10-15<br/>min.</span>
                           </div>
                           <div className="img3box-shadow">
                              <img src="img/shadow-image.png" alt="10-15 min shadow"/>
                           </div>
                        </div>
                        <div className="img3box-col">
                           <div className="img3box-title">
                              <h4>Average Duration</h4>
                              <p>Sufficient anesthesia with IHEEZO lasted an average of 21.5 minutes </p>
                           </div>
                           <div className="img3box-orig">
                              <img src="img/duration.png" alt="21.5 min"/>
                              <span>21.5<br/>min.</span>
                           </div>
                           <div className="img3box-shadow">
                              <img src="img/shadow-image.png" alt="21.5 min shadow"/>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="pro-col-R">
                     <h4>In the IHEEZO clinical trial<span className="su" style={{display:"inline"}}>1</span>:</h4>
                     <div className="para para-list">
                     <ul className="text-left">
                        <li>
                           <p>Rapid onset of less than <b>90 seconds</b> to achieve sufficient anesthesia</p>
                        </li>
                        <li>
                           <p>Many typical ophthalmic procedures last <b>10 to 15 minutes*</b></p>
                        </li>
                        <li>
                           <p>Sufficient anesthesia with IHEEZO lasted an average of <b>21.5 minutes</b> </p>
                        {/* </li>
                        <li> */}
                        <br/>
                           <p style={{fontSize:"14px"}}>*The Phase III clinical trial was a randomized, prospective, multicenter, active-controlled, observer-masked study in 338 patients undergoing routine cataract surgery.</p>
                        </li>
                     </ul>
                     </div>
                  </div>
               </div>
               </div>
            </div>
            <div className="protocol2">
               <div className="container">
                  <div className="pro2box">
                     <p style={{maxWidth:"600px", margin:"auto"}}>No patient treated with IHEEZO in this clinical trial required supplemental treatment to maintain anesthesia.<span className="su">†</span></p>
                  </div>
               </div>
            </div>
            <div className="protocol3">
               <div className="container">
               <div className="pro-row">
                  <div className="pro-col-L">
                     <h2>Proven safety profile</h2>
                     <div className="para para-list">
                        <p>In the clinical trials, the most common adverse reactions following IHEEZO administration (n=151) were<span className="sup">2</span>:</p>
                        <ul>
                           <li><p>Mydriasis (26%)</p></li>
                           <li><p>Conjunctival hyperemia (11%)</p></li>
                           <li><p>Eye irritation (6%)</p></li>
                        </ul>
                     </div>
                     <div className="para">
                        <p>IHEEZO is contraindicated in patients with a history of hypersensitivity to any component of this preparation.<span className="sup">2</span></p>
                     </div>
                     <div className="para">
                        <p>IHEEZO is a unique formulation of chloroprocaine, which has an established safety profile comparable to other local anesthetics.<span className="sup">1</span></p>
                     </div>
                     <div className="para">
                        <p>IHEEZO is preservative-free, and delivered in a sterile, single-use ampule for added patient safety.<span className="sup">3</span></p>
                     </div>
                  </div>
                  <div className="pro-col-R">
                     <div className="safety-box">
                        <img src="img/safety.png" alt="safety"/>
                     </div>
                  </div>
               </div>
               </div>               
            </div>


            <div className="protocol4">
               <div className="container">
               <div className="pro-row">
                  <div className="pro-col-L">
                     <h2>No compromises on satisfaction</h2>
                     {/* <div className="para">
                        <p><b>100% of patients</b> treated with IHEEZO in the Phase III clinical trial reported overall satisfaction with their level of comfort.<span className="sup">1</span></p>
                     </div> */}
                     <div className="para para-30">
                        <p>Surgeons reported that they felt confident using IHEEZO in the clinical trial, calling it <b>“uncomplicated”</b> at each time point of the study.<span className="sup">1</span></p>
                     </div>
                     <div className="button-div">
                        <a href="/order">Learn how to order IHEEZO</a>
                     </div>
                  </div>
                  <div className="pro-col-R long-m">
                     <div className="pro4-box">
                        <p>Sufficient ocular surface anesthesia with IHEEZO in routine ocular procedures may help reduce the need for intraoperative opioids.<span className="su">1,4</span></p>
                     </div>
                  </div>
               </div>
               <div className="para last-para">
                  <p style={{fontSize:"14px"}}><span className="sup">†</span>In the clinical trial, no patient undergoing routine cataract surgery receiving IHEEZO required supplemental treatment to maintain anesthesia; this was not the case for patients receiving tetracaine. Supplemental treatment was defined as general anesthesia. Though supplemental administration was not required by any patient in the clinical trial, IHEEZO may be reapplied as needed to maintain anesthesia.<span className="sup">1</span></p>
               </div>
               </div>
            </div>
         </div>
      </section>
      <Footer/>
     
      
        </>
    )
}
export default About
