import React, { useState } from "react";

import Header from "../common/Header";
import Footer from "../common/Footer";

const Secure = () => {
   const [hide, sethide] = useState(true);
   
   return (
      <>

         <section className="banner">
            <div className="container">
               <div className="banner-text">
                  <h1>IHEEZO Gives You Nothing</h1>
                  <div className="banner-text-h3-desk">
                     <h3>No compromises for your patients.</h3>
                     <h3>No uncertainty with a sterile,</h3>
                     <h3>single-use unit.</h3>
                  </div>
               </div>
            </div>
         </section>
         {/* <!-- ==============================SEPARETELY REIMBUSED SECTION--> */}
         <section className="home-s2">
            <div className="container">
               <div className="row">
                  <div className="col-left">
                     <div className="content-box">
                        <div className="banner-text-h3-mob">
                           <h3>No compromises for your patients.</h3>
                           <h3>No uncertainty for you.</h3>
                        </div>
                        <div className="para">
                           <p>IHEEZO™ is a low-viscosity topical ocular anesthetic with reliable efficacy, proven safety profile, and simple administration for efficient procedures.<span className="sup">1-3</span></p>
                        </div>
                        <div className="para">
                           <p>Many protocols for ocular surface anesthesia involve the use of multiple drugs and application methods, adding time and complexity to procedures.<span className="sup">4</span></p>
                        </div>
                        <div className="para last-para-10">
                           <p>In a Phase III clinical trial of IHEEZO, one dose of IHEEZO has been shown to achieve sufficient ocular surface anesthesia in less than 90 seconds and last an average of 21.5 minutes, which may make it an ideal local anesthetic for patients undergoing routine ocular procedures.<span className="sup">*2</span></p>
                        </div>
                        <div className="button-div" style={{ marginTop: "20px", marginBottom: "20px"  }}>
                           <a href="/order">Learn how to order IHEEZO</a>
                        </div>
                     </div>
                  </div>
                  <div className="col-right">
                     <div className="order-iheezo-box">
                        <h2>Separately reimbursed</h2>
                        <p>IHEEZO is approved with a permanent, unique J-code to help facilitate reimbursement.</p>
                        <div className="code">J-2403</div>
                        <div className="button-div width100">
                           <a href="/reimbursement">View reimbursement information</a>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="para-light" style={{marginTop:"10px"}}>
                  <p><span className="suip">*</span>The Phase III clinical trial was a randomized, prospective, multicenter, active-controlled, observer-masked study in patients undergoing routine cataract surgery.</p>
               </div>
            </div>
         </section>
         {/* <!-- ==============================APPROVED USE SECTION--> */}
         <Footer />
      </>
   )
}
export default Secure
